import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="content">
        <div className="image-container">
          <img
            src="image.png"
            alt="Puzzle pieces"
            className="puzzle-image"
          />
        </div>
        <h1 className="title">Coming Soon</h1>
        <p className="subtitle">
          Find perfectly matched and expertly vetted household help for all your needs
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
